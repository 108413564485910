<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="text-center" style="margin-bottom: 5px">
              <h1 class="text-white">
                Activate your FirstLook account
              </h1>
              <p class="text-lead text-white">
                Setting a password for your account activates it.
                You will automatically be logged in after activation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  placeholder="Password"
                  type="password"
                  name="Password"
                  v-model="password"
                >
                </base-input>
                <password
                  class="mb-3"
                  v-model="password"
                  :strength-meter-only="true"
                  @score="showScore"
                  :showStrengthMeter="false"
                />

                <validation-error :errors="apiValidationErrors.password" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  placeholder="Confirm Password"
                  type="password"
                  name="Password confirmation"
                  v-model="password_confirmation"
                >
                </base-input>
                <validation-error
                  :errors="apiValidationErrors.password_confirmation"
                />

                <div class="text-muted font-italic" v-if="password">
                  <small
                    >Password strength:
                    <template v-if="scors === 0">
                      <span class="text-danger font-weight-700">
                        very weak
                      </span>
                    </template>

                    <template v-if="scors === 1">
                      <span class="text-danger font-weight-700"> weak </span>
                    </template>

                    <template v-if="scors === 2">
                      <span class="text-warning font-weight-700"> medium </span>
                    </template>

                    <template v-if="scors === 3">
                      <span class="text-success font-weight-700"> strong </span>
                    </template>

                    <template v-if="scors === 4">
                      <span class="text-success font-weight-700">
                        very strong
                      </span>
                    </template>
                  </small>
                </div>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Sign in</base-button
                  >
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <a href="/password/reset" class="text-light"
                ><small>Forgot password?</small></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";

export default {
  components: {
    ValidationError,
    Password
  },
  mixins: [formMixin],
  data: () => ({
    scors: '',
    password: null,
    password_confirmation: null
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    showScore(score) {
      this.scors = score;
    },
    async handleSubmit() {
      const user = {
        data: {
          type: "token",
          attributes: {
            id: this.$route.params.id,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("activate/activateAndSetPassword", user);
        this.$notify({
          type: "success",
          message: "Successfully activated.",
        });
        this.$router.push({
          name: "Login"
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with the activation!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async login() {
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("login", { user, requestOptions });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: error.response.data.errors[0].detail,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
